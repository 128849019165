import Cookies from "js-cookie";

class Api {
  static lang = "fr_FR";
  static get langWithoutLocale() {
    const lang = Api.lang.split("_")[0];
    if (!lang) return Api.lang;
    return lang;
  }
  static get BASE_URL() {
    const query = `lang=${Api.langWithoutLocale}&action=`;

    const host = window.location.hostname;
    let SRV_URL = '';
    switch (host) {
      case "127.0.0.1":
        SRV_URL="http://127.0.0.1/miner/api.php";
        break;
      case "preprod.app-moba.com":
        SRV_URL="https://next1.app-moba.com/test/api.php";
        break;
      default:
        SRV_URL="https://next1.app-moba.com/miner/api.php";
    }

    return `${SRV_URL}?${query}`;
  }

  async fetch(action, data, cb) {
    let url = Api.BASE_URL + action;
    let post = "";

    //let formData = new FormData();
    //for (let k in data) formData.append(k, data[k]);

    for (let k in data) {
      if (typeof data[k] == "object") {
        if (data[k] === null || data[k].length === 0) {
          post += "&" + k + "=";
          continue;
        }
        for (let i in data[k]) {
          post += "&" + k + "[]=" + data[k][i];
        }
      } else {
        post +=
          "&" + k + "=" + encodeURIComponent(data[k]).replace(/%20/g, "+");
      }
    }
    if (window.location.hostname=="127.0.0.1") console.log(url+post);

    //
    //		if (data.batch)
    let sending = true;

    const response = await fetch(url + post, {
        method: "get",
        // mode: "cors",
        headers: {
            Accept: "application/json",
            Cookie: Cookies.get("miner"),
        },
        credentials: "include",
        //body: formData,
    });

    let responseParsed;
    
    try {
        responseParsed = await response.json();
    } catch (error) {
        console.error("Error:", error);
        responseParsed = {};
    }

    sending = false;
    if (cb) {
        await cb(responseParsed);
        return;
    } else {
        return responseParsed;
    }

  }
}

export default Api;
