import React, { useEffect } from "react";
import { AnalyticsStat } from "./AnalyticsStat";
import {
  convertMiles,
  loc,
  getLocalString,
} from "../../helpers";
import Table from "./Table";

import mixpanel from "mixpanel-browser";

export default function AnalyticsCar(props) {
  const processOdometer = (dataRow) => {
    const unit = props.moba.unit;
    let odometerAvg = dataRow.odometer.avg;
    if (!odometerAvg) {
      odometerAvg = "-";
    } else {
      if (unit === "miles") {
        odometerAvg = convertMiles(odometerAvg);
      }
      odometerAvg = getLocalString(Math.round(odometerAvg), props.lang) +
        (unit === "miles" ? " miles" : " km");
    }
    return odometerAvg;
  }

  const processSOH = (dataRow) => {
    let sohAvg = dataRow.soh.avg;
    if (!sohAvg) {
      sohAvg = "-";
    } else {
      sohAvg = Math.round(Math.min(sohAvg, 100) * 10) / 10 + "%";
    }
    return sohAvg;
  }

  const processDegradation = (dataRow) => {
    let degradationAvg = dataRow.degradation.avg;
    if (!degradationAvg) {
      degradationAvg = "-";
    } else {
       degradationAvg = Math.round(degradationAvg * 10) / 10 + " %/" + loc("an", props.lang);
    }
    return degradationAvg;
  }

  const processAge = (dataRow) => {
    let ageAvg = dataRow.age.avg;
    if (!ageAvg) {
      ageAvg = "-";
    } else {
      ageAvg = Math.round(ageAvg) + " mois";
    }
    return ageAvg;
  }


  const getData = () => {
    const data = props.data;
    const dataProcessed = data.map((dataRow) => {
      if (dataRow.name != undefined) {
        dataRow.brand = dataRow.name.split(" ")[0];
        //dataRow.model is all that is left
        dataRow.model = dataRow.name.split(" ").slice(1).join(" ");
      }
      dataRow.odometer_avg = processOdometer(dataRow);
      dataRow.soh_avg = processSOH(dataRow);
      dataRow.degradation_avg = processDegradation(dataRow);
      dataRow.age_avg = processAge(dataRow);

      return dataRow;
    });
    return [...dataProcessed];
  };

  useEffect(() => {
    mixpanel.track("User Opened Page", { page: "Analytics", subpage: "Cars" });
  }, []);

  const data = getData();
//  console.log(JSON.stringify(data));
  return (
    <div className="my-4 flex-1 flex items-start" id="TABLE_CONTAINER">
      <div className="text-gray-900 h-full flex-1">
        <main className="mx-auto h-full">
          <Table
            columns={props.columns}
            data={data}
            filterAnalytics={true}
            changeFiltersAnalytics={props.changeFiltersAnalytics}
            lang={props.lang}
            type={props.type}
            analyticsStat={props.analyticsStat}
            adduser={true}
            moba={props.moba}
          />
        </main>
      </div>
      {props.analyticsStat && (
        <div className="flex h-full">
          <div className="">
            <AnalyticsStat
              nb_models={props.analyticsStat.nb_models}
              nb_models_reference={props.analyticsStat.nb_models_reference}
              nb_diagnostics={props.analyticsStat.nb_diagnostics}
              nb_diagnostics_reference={
                props.analyticsStat.nb_diagnostics_reference
              }
              average_soh={props.analyticsStat.average_soh}
              average_soh_reference={props.analyticsStat.average_soh_reference}
              average_age={props.analyticsStat.average_age}
              average_age_reference={props.analyticsStat.average_age_reference}
              average_degradation={props.analyticsStat.average_degradation}
              average_degradation_reference={
                props.analyticsStat.average_degradation_reference
              }
              average_odometer={props.analyticsStat.average_odometer}
              average_odometer_reference={
                props.analyticsStat.average_odometer_reference
              }
              lang={props.lang}
              unit={props.moba.unit}
            />
          </div>
        </div>
      )}
    </div>
  );
}
