import React, { Component } from "react";
import NavBar from "./NavBar";
import { loc } from "../../helpers";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			connected: props.connected,
			manage_display_credit: props.manage_display_credit ?? false,
			moba: null,
			quota: 0,
			display_credit: false,
			display_credit_refill_button: false,
			string_remaining_credits : loc("crédit restant", this.props.lang),
		};

		this.handleShowNavBar = () => {
			var x = document.getElementById("navbar");
			if (x.style.display === "block") {
				x.style.display = "none";
			} else {
				x.style.display = "flex";
			}
		};
	}

	componentDidMount() {
		this.setState({
			moba: this.props.moba,
			quota: this.props.moba.entity_details.quota,
		});
		/*if (
			this.props.moba.details.user.role === "Super Admin" &&
			!this.props.moba.subscription
		) {
			this.setState({
				print_credits: true,
			});
		}*/
		if (
			this.props.moba.entity_details.quota_display === 1 
			&& this.props.manage_display_credit === true 
			&& this.props.moba.details.user.role === "Super Admin"
		) {
			this.setState({
				display_credit: true,
			});

			// A améliorer, hack pour gérer du pluriel...
			if (this.props.moba.entity_details.quota > 1 ) {
				this.setState({
					string_remaining_credits: loc("crédits restants", this.props.lang),
				});
			}
		}
	}

	render() {
		if (!this.state.connected || this.state.moba == null) {
			return null;
		} else
			return (
				<div>
					<div className="mt-3 flex w-full items-center text-grey-800 font-aeonik">
						<div className="flex flex-row text-base items-center ml-4">
							<p className={"whitespace-nowrap " +
								(this.state.display_credit === true ?
									"d-block" : "d-none")
							} >
								<b>{this.state.quota}</b> {this.state.string_remaining_credits}
							</p>
							{/*<button
								className={
									"w-48 ml-16 bg-branding-500 rounded-md text-white font-medium text-base p-2 px-4 whitespace-nowrap " +
									(this.state.display_credit_refill_button === true ?
										"d-block" : "d-none"
									)
								}
							>
								{loc("Recharger", this.props.lang)}{" "}
							</button>
							*/}
						</div>

						<div className="flex flex-row ml-auto mr-10 p-1 items-center dropbtn mt-2">
							<img
								//seed with first letter of firstname and lastname
								src={
									"https://api.dicebear.com/6.x/initials/svg?radius=50&seed=" +
									this.props.moba.details.user.firstname.charAt(
										0
									) +
									this.props.moba.details.user.lastname.charAt(
										0
									)
								}
								width={35}
								alt="avatar"
								className="mr-4"
							/>

							<div className="flex flex-col">
								<button
									onClick={this.handleShowNavBar}
									className="flex flex-row items-center"
								>
									<p className="mr-3">
										{this.props.moba.details.user.firstname}{" "}
										{this.props.moba.details.user.lastname}
									</p>
									<img
										src="assets/navigation.svg"
										alt="navigation"
									/>
								</button>
							</div>
							<div>
								<NavBar lang={this.props.lang}></NavBar>
							</div>
						</div>
					</div>
				</div>
			);
	}
}

export default Header;
