import React from "react";
import HeaderLogin from "./HeaderLogin";
import { useCookies } from "react-cookie";
import { loc } from "../../helpers";
import FooterLogin from "../components/FooterLogin";

export default function Thanks(props) {
	const [removeCookie] = useCookies();

	return (
		<div className="flex flex-col m-0 min-h-screen">
			<HeaderLogin lang={props.lang} />
			<div className="flex flex-col m-auto">
				<form
					className="flex flex-col mx-auto font-aeonik max-w-3xl"
					onSubmit={async event => {
						window.location.reload(false);
					}}
				>
					{" "}
					<div className="flex flex-col">
						<div className="flex flex-col m-5">
							<p className="font-aeonik font-medium text-3xl text-center text-grey-700">{loc("Merci", props.lang)} !</p>
							<p className="font-aeonik font-normal text-base flex items-center text-center text-grey-700 mt-8">
								{loc(
									"Un conseiller Moba prendra prochainement contact avec vous pour vous aider à paramétrer votre compte."
								)}
							</p>

							<div className="flex flex-col mt-12">
								<button
									type="submit"
									className="bg-branding-500 px-4 py-2 rounded-md flex w-full"
								>
									<p className="text-white mx-auto">
										{loc("Accéder au dashboard", props.lang)}
									</p>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<FooterLogin lang={props.lang} />
		</div>
	);
}
